// ==================================================== BUTTONS CLOSE COOKIES======================================================
/*
========================================== Close message cookies =========================================

● This IIFE function checks if the localstorage has the variable "closeMessage" with a value of true.
● If the value is true, the cookies message will no longer be displayed on the screen.
● In order for the message to be displayed again, it is necessary to delete the cookies from the browser.
● The variable "closeMessage" in localstorage will take the value of true when the event is executed by pressing the x of the alert.
===============================================================================================================*/

var showCookie = false;

const popupCheckout = document.getElementById("modal-check");

const btnCloseMessageCookies = document.querySelector(".checkout-alert-register-card-close");

(function () {

    const storage = localStorage.getItem("closeMessage");

    if (storage) {  

        popupCheckout.classList.add("d-none");
        
    } else {

        popupCheckout.classList.remove("d-none");

    }

})();

btnCloseMessageCookies.addEventListener("click", function () {

    localStorage.setItem("closeMessage", true);

    popupCheckout.classList.add("d-none");

});

var showCookie2 = false;
const popupCheckout2 = document.getElementById("modal-check");

const btnCloseMessageCookiesx = document.querySelector(".checkout-alert-register-card-closex");

(function () {

    const storage = localStorage.getItem("closeMessage");

    if (storage) {  

        popupCheckout2.classList.add("d-none");
        
    } else {

        popupCheckout2.classList.remove("d-none");

    }

})();

btnCloseMessageCookiesx.addEventListener("click", function () {

    localStorage.setItem("closeMessage", true);

    popupCheckout2.classList.add("d-none");

});